import { useContext, useEffect, useState } from 'react'
import { cn, downloadFile, highlightText, truncateContent } from 'utils/helper'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/sheet/sheet'
import { Link } from 'react-router-dom'
import HtmlContent from './htmlContent'
import { Button, useAPI, ViewContext } from 'components/lib'
import parse, { domToReact } from 'html-react-parser'
import classNames from './viewDocument/viewDocument.tailwind'
import { useWindowSize } from 'hooks/useWindowSize.hook'
import { useDebug } from 'hooks/useDebug'
import useCallAPI from 'components/hooks/callApi'

const options = {
  replace: ({ name, attribs, children }) => {
    if (!attribs) return
    if (name === 'b') {
      return <b style={{ backgroundColor: '#ebcfea' }}>{domToReact(children, options)}</b>
    }
  },
}

const DocumentPreview = ({ fullTitle, documentType, source, documentId, superType }) => {
  const { data: documentFile, loading: isLoading } = useAPI(`/api/search/document/${documentType}/${documentId}`)
  return (
    <div className={`max-h-full overflow-y-scroll ${documentFile?.html_url ? 'px-4 md:px-8' : ''}`}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <HtmlContent html_url={documentFile?.html_url} pdf_url={documentFile?.pdf_url} />
      )}
    </div>
  )
}

const DebugPanel = ({ debugValues }) => {
  const debugItems = [
    { label: 'Relevant vs Recent', value: debugValues.relevantVsRecent },
    { label: 'Temporal Decay Score', value: debugValues.temporal_decay_score },
    { label: 'Semantic Score', value: debugValues.semantic_score },
    { label: 'Title Score', value: debugValues.full_title_score },
    { label: 'Title Weight', value: debugValues.titleWeight },
    { label: 'Title Score Weighted', value: debugValues.titleScoreWeighted },
    { label: 'Text Score', value: debugValues.text_score },
    { label: 'Overall Score', value: debugValues.scoreUnweighted },
    { label: 'Overall Weight', value: debugValues.overallWeight },
    { label: 'Overall Score Weighted', value: debugValues.score },
    { label: 'Mode', value: debugValues.mode },
  ]

  return (
    <div className="mt-4 p-3 bg-gray-100 border border-gray-300 rounded-md">
      <div className="font-mono text-xs text-gray-500 mb-2">Debug Scores Panel</div>
      {debugItems
        .filter(({ value }) => value !== undefined)
        .map(({ label, value }, index, arr) => (
          <p
            key={label}
            className={cn('flex justify-between py-1', index !== arr.length - 1 && 'border-b border-gray-200')}
          >
            <span className="font-semibold">{label}:</span>
            <span>{value}</span>
          </p>
        ))}
    </div>
  )
}

const ResultItem = ({
  id,
  fullTitle,
  superType,
  documentType,
  text,
  highlight,
  dateModified,
  judgementDate,
  headline,
  documentId,
  summary,
  datePublished,
  source,
  origin_url,
  ...debugValues
}) => {
  const { isDebugMode } = useDebug()
  const viewContext = useContext(ViewContext)
  const [expanded, setExpanded] = useState(false)
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth && windowWidth <= 767
  const maxContentLength = 200
  const displayDate = datePublished
    ? new Date(datePublished).toLocaleDateString()
    : judgementDate
      ? new Date(judgementDate).toLocaleDateString()
      : dateModified
        ? new Date(dateModified).toLocaleDateString()
        : ''

  const [callDocumentAPI, documentFile, documentLoading, documentError] = useCallAPI({
    url: `/api/search/document/${documentType}/${documentId}`,
    method: 'GET',
  })

  useEffect(() => {
    if (callDocumentAPI && expanded) {
      callDocumentAPI()
    }
  }, [callDocumentAPI, expanded])

  return (
    <div className="p-1 md:p-4" key={id} id="result-item">
      <Sheet>
        {source !== 'Legislation' ? (
          <SheetTrigger>
            <p className="flex items-start text-[#0C254D] text-left font-bold hover:underline text-sm md:text-base hover:text-[#12367F]">
              <span
                className={
                  'flex-shrink-0 w-0.5 md:w-1 mr-2 self-stretch' + (headline ? ' bg-[#c342e0]' : ' bg-[#12367F]')
                }
              ></span>
              {fullTitle}
            </p>
          </SheetTrigger>
        ) : (
          <a
            className="flex items-start text-[#0C254D] text-left font-bold hover:underline text-base hover:text-[#12367F]"
            href={origin_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span
              className={
                'flex-shrink-0 w-0.5 md:w-1 mr-2 self-stretch' + (headline ? ' bg-[#c342e0]' : ' bg-[#12367F]')
              }
            ></span>
            {fullTitle}
          </a>
        )}
        <SheetContent className="bg-white flex flex-col gap-0">
          <SheetHeader className=" border-b p-3 lg:p-4 border-gray-200">
            <SheetTitle className="flex w-full justify-between items-center">
              <Link
                to={
                  source !== 'Legislation'
                    ? {
                        pathname: `/document/${superType}/${documentType}/${documentId}`,
                        search: `?fullTitle=${fullTitle}`,
                      }
                    : { pathname: origin_url }
                }
                className="text-primary-dark-blue text-left font-bold  w-[calc(100%-1.5rem)] hover:underline text-xs lg:text-base hover:text-primary-dark inline-flex items-center gap-8"
                rel="noopener noreferrer"
              >
                <p className="serif">{fullTitle}</p>
              </Link>
              {superType === 'Case' && (
                <>
                  <div className="mx-2 flex">
                    <Button
                      icon="copy"
                      color="#12367F"
                      size={isMobile ? 14 : 16}
                      action={() => {
                        const endsWithBracketedContent = /\(([^)]*)\)$/
                        const match = fullTitle.match(endsWithBracketedContent)[1]
                        try {
                          new Date(match)
                          // If the last part of the title is a date, remove it before copying
                          navigator.clipboard.writeText(fullTitle.replace(/\([^)]*\)$/, '').trim())
                          viewContext.notification.show('Citation copied to clipboard', 'success', true)
                        } catch {
                          navigator.clipboard.writeText(fullTitle)
                        }
                      }}
                      className={cn(classNames.button, '!w-6 !h-6 lg:!h-12 lg:!w-12')}
                    />
                  </div>
                  {documentFile?.pdf_url && (
                    <div className="flex">
                      <Button
                        icon="download"
                        color="#12367F"
                        size={isMobile ? 14 : 16}
                        action={() => downloadFile(documentFile.pdf_url, fullTitle || 'document.pdf')}
                        className={cn(classNames.button, '!w-6 !h-6 lg:!h-12 lg:!w-12')}
                      />
                    </div>
                  )}
                  {documentError && (
                    <div className="flex">
                      <p className="text-red-500">Error loading document download</p>
                    </div>
                  )}
                </>
              )}
              <SheetClose className="text-gray-400 hover:text-primary-dark-blue">
                <div className="flex ml-2 mr-1 cursor-pointer font-sans !h-6 !w-6 lg:!h-12 lg:!w-12 rounded-lg [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20 bg-white shadow-sm relative p-0">
                  {/* <CrossIcon width={12} height={12} fill="#475569" /> */}
                  <svg
                    width={`${isMobile ? '16' : '19'}`}
                    height={`${isMobile ? '16' : '19'}`}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-download absolute top-1/2 left-0 -translate-y-1/2"
                    color="#12367F"
                  >
                    <g>
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </g>
                  </svg>
                </div>
              </SheetClose>
            </SheetTitle>
          </SheetHeader>
          <SheetDescription className="h-full overflow-y-scroll">
            <DocumentPreview
              fullTitle={fullTitle}
              documentType={documentType}
              source={source}
              documentId={documentId}
              superType={superType}
            />
          </SheetDescription>
        </SheetContent>

        <div className="">
          <p className="text-[11px] md:text-xs text-[#475569] py-1 md:py-2">
            {superType} <span className="opacity-40">{' | '}</span>
            {documentType}
            {displayDate && (
              <>
                <span className="opacity-40">{' | '}</span>
                <span className="date">{displayDate}</span>
              </>
            )}
          </p>
        </div>
        {summary && (
          <p className="text-[#0C254D] text-xs md:text-sm pb-2">
            {expanded ? highlightText(summary, highlight) : truncateContent(summary, maxContentLength)}
            {summary.length > maxContentLength && (
              <button
                onClick={() => setExpanded((prev) => !prev)}
                className="text-[#12367F] text-xs md:text-sm underline ml-2"
              >
                {expanded ? 'Show less' : 'View more'}
              </button>
            )}
          </p>
        )}

        <p className={`text-[#6C7787] text-xs md:text-sm line-clamp-4  ${headline ? '' : 'bg-sky-100/35 p-1'}`}>
          {headline ? <span>{parse(headline, options)}</span> : highlightText(text, highlight)}
        </p>

        {isDebugMode && <DebugPanel debugValues={debugValues} />}
      </Sheet>
    </div>
  )
}

export default ResultItem
