import React from 'react'
import { useCitation } from '../CitationContext'
import CitationTooltip from './CitationTooltip'
import { ACT, ACTDEF, getSourceWith } from '@lawcyborg/packages'
import { toSnakeCase } from 'utils'

const legislationNames = [ACT.name, ACTDEF.name]

const generateChunkLink = (chunk) => {
  const source = getSourceWith('name', chunk.source)
  const isLegislation = legislationNames.includes(source.superType)

  return isLegislation
    ? chunk.link
    : `${process.env.REACT_APP_S3_BUCKET_URL}/${source.documentTypes.find((doc) => doc.name === chunk.documentType).s3Path}/${toSnakeCase(chunk.title)}/Document.pdf#page=${chunk.page}`
}

const ReferenceList = ({ references, listType = 'decimal', itemPrefix = '' }) => {
  const { hoveredCitationIndex } = useCitation()

  if (!references || references.length === 0) return null

  return (
    <ol className={`ml-6 list-${listType}`}>
      {references.map((citation, index) => (
        <li className="list-item" key={index}>
          <CitationTooltip source={citation}>
            <a
              href={generateChunkLink(citation)}
              target="_blank"
              rel="noreferrer"
              className={`transition-colors duration-300 ease-in-out hover:text-blue-500 block ${hoveredCitationIndex === index ? 'citation-hovered' : ''}`}
              data-citation-index={index}
            >
              {`${itemPrefix}${legislationNames.includes(citation.source) ? citation.documentType + ', s ' : ''}${citation.title}${citation.page ? ' at ' + citation.page : ''}`}
            </a>
          </CitationTooltip>
        </li>
      ))}
    </ol>
  )
}

export default ReferenceList
