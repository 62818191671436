import React, { createContext, useState, useContext } from 'react'

// Create a context for managing citation hover states
export const CitationContext = createContext()

export const CitationProvider = ({ children }) => {
  const [hoveredCitationIndex, setHoveredCitationIndex] = useState(null)

  const handleCitationHover = (index, isHovering) => {
    setHoveredCitationIndex(isHovering ? index : null)
  }

  return (
    <CitationContext.Provider value={{ hoveredCitationIndex, handleCitationHover }}>
      {children}
    </CitationContext.Provider>
  )
}

// Custom hook to use the citation context
export const useCitation = () => {
  const context = useContext(CitationContext)
  if (!context) {
    throw new Error('useCitation must be used within a CitationProvider')
  }
  return context
}
