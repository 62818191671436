import React from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import { useCitation } from '../CitationContext'
import './citationTool.scss'

const CitationTooltip = React.memo(({ source, children }) => {
  const { handleCitationHover } = useCitation()
  const citationIndex = React.Children.only(children).props['data-citation-index']

  if (!source) {
    return children
  }

  const getTooltipContent = () => {
    return source?.summary || `${source.title}${source.page ? ' at page ' + source.page : ''}, ${source.source}`
  }

  return (
    <Tippy
      touch={false}
      content={
        <div className="text-xs leading-snug" role="tooltip" aria-live="polite">
          <div className="font-bold border-b pb-1 mb-1">{`${source?.superType} / ${source?.documentType} / ${source?.title}`}</div>
          <div className="text-justify">{getTooltipContent()}</div>
        </div>
      }
      animation="shift-away"
      inertia={true}
      placement="top"
      theme="light"
      delay={[200, 0]}
      arrow={false}
    >
      <span
        onMouseEnter={() => handleCitationHover(citationIndex, true)}
        onMouseLeave={() => handleCitationHover(citationIndex, false)}
      >
        {children}
      </span>
    </Tippy>
  )
})

export default CitationTooltip
