/***
 *
 *   Settings
 *   Manage account settings
 *
 *
 **********/

import React, { Fragment, useEffect, useState, useContext } from 'react'
import { AccountNav, Animate, Card, Form, ViewContext } from 'components/lib'
import Animatedloader from 'components/loader/animatedloader'
import { SettingsContext } from 'app/settings'

const settingsMap = {
  chat_history: {
    displayText: 'Chat History',
    hint: 'Your users chats will be saved. Saving happens automatically and users will be able to switch between saved chats but not delete them. Turning this setting off will delete all chat histories of all users on this account.',
  },
  answer_feedback: {
    displayText: 'Hide Answer Feedback',
    hint: 'Your users will not be able to share conversation feedback to the Lawcyborg team',
  },
  disable_tool_ai_general: {
    displayText: 'Disable General AI',
    hint: 'Your users will not be able to use the General AI tool',
  },
  disable_tool_document_uploader: {
    displayText: 'Disable Document Uploader',
    hint: 'Your users will not be able to use the document uploader tool',
  },
  disable_tool_provtax_calculator: {
    displayText: 'Disable Provisional Tax Calculator',
    hint: 'Your users will not be able to use the provisioner tax calculator tool',
  },
  disable_tool_depreciation_finder: {
    displayText: 'Disable Depreciation Rate Finder',
    hint: 'Your users will not be able to use the depreciation rate finder tool',
  },
}

export function Settings(props) {
  const [settingsSynced, setSettingsSynced] = useState(false)
  const settingsContext = useContext(SettingsContext)
  const viewContext = useContext(ViewContext)

  // LV A settingsSynced state is required to prevent the form from
  // rendering before the correct settings are loaded. This is
  // because the form component will not rerender if the input prop
  // has different default values. This form behaviour ensures only
  // the users interactions will trigger a rerender, and other
  // rerenders will not wipe the users selections. Therefore we must
  // avoid the first render and wait until we have fetched the up to
  // date settings from the server.
  useEffect(() => {
    const fetchData = async () => {
      await settingsContext.reloadSettings()
      setSettingsSynced(true)
    }
    fetchData()
  }, [])

  let settingsKeys = []
  if (settingsContext.settings) {
    settingsKeys = Object.keys(settingsContext.settings)
  }

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Card title="Settings" restrictWidth className={props.className}>
          {settingsContext.error ? (
            <div>Could not load settings</div>
          ) : settingsSynced ? (
            <Form
              inputs={{
                settings: {
                  type: 'checkbox',
                  options: settingsKeys.map((setting) => settingsMap[setting].displayText || setting),
                  default: settingsKeys
                    .filter((key) => settingsContext.settings[key] === true)
                    .map((setting) => settingsMap[setting].displayText || setting),
                  optionsValues: settingsKeys,
                  hints: settingsKeys.reduce((acc, key) => {
                    if (settingsMap[key] && settingsMap[key].hint) {
                      acc[key] = settingsMap[key].hint
                    }
                    return acc
                  }, {}),
                },
              }}
              url="/api/account/settings"
              method="POST"
              buttonText="Save"
              onBeforeSubmit={(data) => {
                let warning = ''
                const currentSettings = settingsKeys.filter((key) => settingsContext.settings[key] === true)
                const newSettings = data.settings.value || []

                if (currentSettings.includes('chat_history') && !newSettings.includes('chat_history')) {
                  warning +=
                    'Disabling chat history will DELETE all chat history for all users connected to the admin account.'
                }
                if (warning === '') {
                  return true
                }
                return new Promise((resolve) => {
                  viewContext.modal.show(
                    {
                      title: 'Warnings',
                      text: warning,
                      form: {},
                      buttonText: 'Save',
                    },
                    () => {
                      resolve(true)
                    }
                  )
                })
              }}
              callback={() => {
                settingsContext.reloadSettings()
              }}
            ></Form>
          ) : settingsContext.loading && !settingsContext.settings ? (
            <Animatedloader />
          ) : null}
        </Card>
      </Animate>
    </Fragment>
  )
}
