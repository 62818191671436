import { useState, useContext, useMemo, useCallback } from 'react'
import { DebugContext } from 'app/debug'
import { useStopwatch } from './useStopwatch.hook'

/**
 * Custom hook for managing debug-related state and functionality
 *
 * @param {Object} initialState - Initial debug state values
 * @returns {Object} Debug state and functions
 */
export const useDebug = (initialState = {}) => {
  const debugContext = useContext(DebugContext)
  const [debugState, setDebugState] = useState(initialState)

  // Initialize stopwatch functionality
  const { elapsedTime, start, stop, reset } = useStopwatch()

  /**
   * Update a specific key in the debug state
   *
   * @param {string} key - The state key to update
   * @param {any} value - The new value
   */
  const updateDebugState = useCallback((key, value) => {
    setDebugState((prev) => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  return {
    // Debug context
    isDebugMode: debugContext.debug,

    // Debug state and updater
    debugState,
    updateDebugState,

    // Timing functions
    elapsedTime,
    start,
    stop,
    reset,
  }
}
