import SearchForm from './searchForm'
import useCallAPI from 'components/hooks/callApi'
import { useAccess, useWindowSize, useDebug } from 'hooks'
import { Link, useSearchParams } from 'react-router-dom'
import ResultItem from './individualResults'
import { Button, Drawer, Pagination, Slider, ViewContext } from 'components/lib'
import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { getCountryKeyByValue, getPracticeAreasByCountry, modes, moduleIds } from '@lawcyborg/packages'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import { useSearch } from './searchContext'
import SearchFilters from 'views/searchAI/searchFilters'
import { useAuth } from 'app/auth'
import { MobileViewportWrapper } from './AIChatWindow'
import { findCheckedOptions } from 'components/filterStateful/utils'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism'

const SearchModeRow = ({ mode, modeConfig, debugState, updateDebugState }) => {
  const modeState = debugState.modes[mode]
  const titleWeightMin = debugState.config?.titleWeight?.min || 0
  const [showExpressions, setShowExpressions] = useState(false)
  const hasExpressions = modeConfig.scoreExpr && Object.keys(modeConfig.scoreExpr).length > 0

  return (
    <div className="mb-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-4">
          <div className="flex items-center w-48">
            <input
              type="checkbox"
              id={`${mode}Results`}
              checked={modeState.enabled}
              onChange={() => {
                const updatedModes = {
                  ...debugState.modes,
                  [mode]: {
                    ...modeState,
                    enabled: !modeState.enabled,
                  },
                }
                updateDebugState('modes', updatedModes)
              }}
              className="mr-2"
            />
            <label htmlFor={`${mode}Results`}>{modeConfig.label}</label>
          </div>
          <div className="flex items-center">
            <label htmlFor={`${mode}OverallWeight`} className="mr-2 text-xs">
              Overall Weight:
            </label>
            <input
              id={`${mode}OverallWeight`}
              type="number"
              min={0}
              step="0.1"
              value={modeState.overallWeight || 1}
              onChange={(e) => {
                const updatedModes = {
                  ...debugState.modes,
                  [mode]: {
                    ...modeState,
                    overallWeight: parseFloat(e.target.value),
                  },
                }
                updateDebugState('modes', updatedModes)
              }}
              className="w-16 p-1 border rounded text-xs"
            />
          </div>
          {modeConfig.titleWeight && (
            <div className="flex items-center">
              <label htmlFor={`${mode}TitleWeight`} className="mr-2 text-xs">
                Title Weight:
              </label>
              <input
                id={`${mode}TitleWeight`}
                type="number"
                min={titleWeightMin}
                step="0.1"
                value={modeState.titleWeight}
                onChange={(e) => {
                  const updatedModes = {
                    ...debugState.modes,
                    [mode]: {
                      ...modeState,
                      titleWeight: parseFloat(e.target.value),
                    },
                  }
                  updateDebugState('modes', updatedModes)
                }}
                className="w-16 p-1 border rounded text-xs"
              />
            </div>
          )}
        </div>
        {hasExpressions && (
          <Button
            size={14}
            action={() => setShowExpressions(!showExpressions)}
            className="text-xs px-2 py-1 h-auto border border-primary-light hover:bg-primary-light"
            text={showExpressions ? 'Hide expressions' : 'Show expressions'}
            textOnly
          />
        )}
      </div>

      {showExpressions && hasExpressions && (
        <div className="ml-8 mt-2 p-3 bg-gray-100 rounded text-xs font-mono">
          <h3 className="font-semibold mb-2">Score Expressions:</h3>
          {Object.entries(modeConfig.scoreExpr).map(([name, expr]) => (
            <div key={name} className="mb-2">
              <div className="font-semibold text-gray-700">{name}:</div>
              <div className="pl-4 pt-1 pb-1 bg-white rounded border" style={{ overflowX: 'auto' }}>
                <SyntaxHighlighter
                  language="sql"
                  style={vs}
                  customStyle={{
                    margin: 0,
                    padding: '0.25rem',
                    fontSize: '14px',
                    backgroundColor: 'transparent',
                    wordBreak: 'normal',
                    whiteSpace: 'pre',
                    overflowWrap: 'normal',
                  }}
                  wrapLines={false}
                  wrapLongLines={false}
                >
                  {expr}
                </SyntaxHighlighter>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const DebugTools = ({ debugState, updateDebugState, elapsedTime }) => {
  const [topK, setTopK] = useState(debugState.topK || 50)

  if (!debugState.config) {
    return <div className="flex flex-col p-4 mb-4 border rounded bg-gray-50">Loading debug configuration...</div>
  }

  const { results, labels, modes: modesConfig } = debugState.config

  return (
    <div className="flex flex-col p-4 mb-4 border rounded bg-gray-50 max-w-[800px] mx-auto">
      <h2 className="uppercase font-semibold text-sm text-primary-dark m-1 py-2">{labels.topk}</h2>
      <div className="flex">
        <p className="mr-3 mt-1">{results.min}</p>
        <Slider
          onValueCommit={(e) => {
            updateDebugState('topK', e[0])
            setTopK(e[0])
          }}
          max={results.max}
          min={results.min}
          step={1}
          defaultValue={results.default}
          hoverValue={topK}
        />
        <p className="ml-3 mt-1">{results.max}</p>
        <Tippy content={labels.tooltip} arrow={roundArrow} animation="shift-away" inertia={true}>
          <div className="px-3">
            <Button
              icon={'alert-circle'}
              color={'dark'}
              size={20}
              className={
                'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
              }
            />
          </div>
        </Tippy>
        <div>
          <p>{`Server Query Time (s): ${debugState.serverQueryTime}`}</p>
          <p>{`Elapsed Time (s): ${elapsedTime / 1000}`}</p>
        </div>
      </div>

      <h2 className="uppercase font-semibold text-sm text-primary-dark m-1 py-2 mt-2">{labels.modesTitle}</h2>
      <div className="border-t pt-2">
        {Object.entries(modesConfig).map(([mode, config]) => (
          <SearchModeRow
            key={mode}
            mode={mode}
            modeConfig={config}
            debugState={debugState}
            updateDebugState={updateDebugState}
          />
        ))}
      </div>
    </div>
  )
}

const FilterContent = ({
  setRelevancyVsRecency,
  relevancyVsRecency,
  availablePracticeAreas,
  hasAccess,
  selectedPracticeAreaId,
  className = '',
}) => {
  return (
    <div className={`${className}`}>
      <div className="gap-4 my-2 lg:my-4">
        <h2 className="uppercase font-semibold text-xs lg:text-sm text-primary-dark py-1 lg:py-2">Recency</h2>
        <div className="flex">
          <Slider
            onValueCommit={(e) => setRelevancyVsRecency(e[0])}
            max={1}
            min={0}
            step={0.01}
            defaultValue={relevancyVsRecency || 0}
          />
          <Tippy
            touch={false}
            content={`Adjust how much recency is taken into account for a given search.`}
            arrow={roundArrow}
            animation="shift-away"
            inertia={true}
          >
            <div className="px-3">
              <Button icon={'alert-circle'} color={'dark'} size={20} />
            </div>
          </Tippy>
        </div>
      </div>
      <SearchFilters
        practiceArea={Object.values(availablePracticeAreas).filter(
          (pa) => hasAccess(pa.id) || hasAccess(moduleIds.NZ_PRACTICE_AREA_BUNDLE)
        )}
      />
      {!selectedPracticeAreaId && (
        <Tippy
          touch={false}
          content={`Law Cyborg's Elite Plan is in early access. If you have feedback or suggestions, please get in touch.`}
          arrow={roundArrow}
          animation="shift-away"
          inertia={true}
        >
          <div className="absolute right-4 bottom-4">
            <Button
              icon={'alert-circle'}
              color={'dark'}
              size={20}
              className={
                'bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20'
              }
            />
          </div>
        </Tippy>
      )}
    </div>
  )
}

const FilterSection = ({
  setRelevancyVsRecency,
  relevancyVsRecency,
  availablePracticeAreas,
  hasAccess,
  selectedPracticeAreaId,
}) => {
  const { width: windowWidth } = useWindowSize()
  const isMobile = windowWidth && windowWidth <= 767
  const [isOpen, setIsOpen] = useState(false)

  const filterProps = {
    setRelevancyVsRecency,
    relevancyVsRecency,
    availablePracticeAreas,
    hasAccess,
    selectedPracticeAreaId,
  }

  return (
    <>
      {isMobile ? (
        <Drawer
          triggerButton={
            <Button
              icon={'sliders'}
              color={'#475569'}
              action={() => setIsOpen((prev) => !prev)}
              className={'!absolute top-5 right-5 '}
            />
          }
          title="Filter"
          open={isOpen}
          onOpenChange={setIsOpen}
          sheetSide="right"
          className={'bg-red-500'}
        >
          <div className="p-0 md:p-4">
            <h2 className="font-bold text-base lg:text-xl text-[#0C254D]">Filter</h2>
            <FilterContent {...filterProps} />
          </div>
        </Drawer>
      ) : (
        <div className="hidden md:block right my-4 lg:ml-8 min-w-64 max-w-72">
          <div className="flex w-full pb-5 border-b-[1px]">
            <h2 className="inline font-bold text-xl text-[#0C254D]">Filter</h2>
          </div>
          <FilterContent {...filterProps} className="items-center px-2 mt-8" />
        </div>
      )}
    </>
  )
}

const SearchResults = () => {
  const [isLogoVisible, setIsLogoVisible] = useState(false)
  const handleToggle = () => {
    setIsLogoVisible((prev) => {
      return !prev
    })
  }
  const viewContext = useContext(ViewContext)
  const hasAccess = useAccess()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    searchResults,
    setSearchResults,
    searchInputValue,
    strictSearch,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    setSubmittedSearchValue,
    selectedNamespaces,
    selectedCategories,
    selectedPracticeAreaId,
    relevancyVsRecency,
    setRelevancyVsRecency,
    searchQueued,
    setSearchQueued,
  } = useSearch()
  const { accounts } = useAuth()
  const userCountry = getCountryKeyByValue(accounts[0].country)
  // FIXME: this is a temporary fix for the region code, we need to get the region from the user's account
  const region = userCountry === 'au' ? 'nsw' : undefined
  const availablePracticeAreas = getPracticeAreasByCountry(userCountry, region)

  const startIndex = (currentPage - 1) * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const [paginatedResults, setPaginatedResults] = useState(
    searchResults ? searchResults.slice(startIndex, endIndex) : []
  )

  const { width: windowWidth } = useWindowSize()
  const [search, results, loading, error] = useCallAPI({
    url: '/api/search',
    method: 'POST',
  })
  const [getDebugConfig] = useCallAPI({
    url: '/api/search/debug-config',
    method: 'GET',
  })

  const isDesktopWide = windowWidth > 1280
  const isDesktop = windowWidth > 1080

  const { isDebugMode, debugState, updateDebugState, elapsedTime, start, stop, reset } = useDebug({
    topK: 50,
    serverQueryTime: 0,
    config: null, // Will be populated from backend
    modes: {},
  })

  useEffect(() => {
    if (results?.data?.results) {
      setSearchResults(results.data.results)
    }
  }, [results, setSearchResults])

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      // Filter results based on mode settings
      const filteredResults = isDebugMode
        ? searchResults.filter((result) => {
            const mode = result.mode || modes.SEMANTIC // Default to semantic if mode not provided
            // LV Allow results to be shown if the mode is not provided in case a user searches
            // then switches to debug mode (mode is not provided outside of debug mode)
            return debugState.modes[mode]?.enabled || !result.mode
          })
        : searchResults // No filtering if not in debug mode

      const startIndex = (currentPage - 1) * rowsPerPage
      const endIndex = startIndex + rowsPerPage
      const newPaginated = filteredResults.slice(startIndex, endIndex)
      setPaginatedResults(newPaginated)
    }
  }, [currentPage, rowsPerPage, searchResults, searchInputValue, setSearchParams, debugState.modes, isDebugMode])

  const filteredResults = useMemo(
    () =>
      isDebugMode
        ? searchResults?.filter((result) => {
            const mode = result.mode || modes.SEMANTIC
            return debugState.modes[mode]?.enabled || !result.mode
          })
        : searchResults,
    [searchResults, debugState.modes, isDebugMode]
  )

  const totalPages = filteredResults.length ? Math.ceil(filteredResults.length / rowsPerPage) : 1

  const handlePageChange = (page) => {
    setCurrentPage(page)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleRowsPerPageChange = (newRows) => {
    setRowsPerPage(newRows)
    setCurrentPage(1)
  }

  useEffect(() => {
    const fetchDebugConfig = async () => {
      try {
        const response = await getDebugConfig()
        const { data: config } = response

        updateDebugState('config', config)

        // Initialize default modes based on the config
        const initialModes = {}
        Object.entries(config.modes).forEach(([mode, settings]) => {
          initialModes[mode] = {
            enabled: settings.enabled,
            titleWeight: settings.titleWeight,
            overallWeight: settings.overallWeight || 1,
          }
        })

        updateDebugState('modes', initialModes)
        updateDebugState('topK', config.results.default)
      } catch (error) {
        console.error('Failed to fetch debug configuration:', error)
      }
    }

    if (isDebugMode) fetchDebugConfig()
  }, [isDebugMode, updateDebugState, getDebugConfig])

  useEffect(() => {
    if (results?.data) stop()
    if (results?.data?.queryTime) updateDebugState('serverQueryTime', results.data.queryTime)
  }, [results, stop, updateDebugState])

  const handleSubmit = useCallback(async () => {
    const trimmedSearch = searchInputValue?.trim() || ''

    if (trimmedSearch === '') {
      viewContext.notification.show('Please enter a search term', 'error')
      return
    }

    setSubmittedSearchValue(searchInputValue)
    setPaginatedResults([])

    if (isDebugMode) {
      reset()
      start()
      updateDebugState('serverQueryTime', 0)
    }

    const filters = {
      practice_area: selectedPracticeAreaId,
      namespaces: findCheckedOptions(selectedNamespaces).flat.map((option) => option.value),
      categories: findCheckedOptions(selectedCategories).nested,
    }

    // Use the specific endpoint based on search mode
    const searchEndpoint = strictSearch ? '/api/search/strict' : '/api/search/smart'

    search({
      url: searchEndpoint,
      requestData: {
        query: searchInputValue,
        filters,
        relevantVsRecent: relevancyVsRecency,
        auto: !selectedPracticeAreaId,
        topK: isDebugMode ? debugState.topK : 200,
        modeSettings: isDebugMode ? debugState.modes : undefined,
      },
    })

    setCurrentPage(1)
  }, [
    search,
    searchInputValue,
    selectedPracticeAreaId,
    selectedCategories,
    selectedNamespaces,
    relevancyVsRecency,
    strictSearch,
    debugState.topK,
    setCurrentPage,
    setSubmittedSearchValue,
    viewContext.notification,
    isDebugMode,
    reset,
    start,
    updateDebugState,
    debugState.modes,
  ])

  useEffect(() => {
    const hasCategories = Object.keys(selectedCategories || {}).length > 0
    const hasNamespaces = Object.keys(selectedNamespaces || {}).length > 0
    const hasInput = searchInputValue !== ''

    if (searchQueued && hasCategories && hasNamespaces && hasInput) {
      setSearchQueued(false)
      handleSubmit()
    }
  }, [searchQueued, selectedCategories, selectedNamespaces, searchInputValue, setSearchQueued, handleSubmit])

  return (
    <div className="md:flex !pt-4 md:gap-2 h-full overflow-y-auto justify-between">
      {isDesktopWide && (
        <div>
          <div className={`logoMargin text-xl my-5 mx-8 font-bold ${isLogoVisible ? '' : 'hidden'}`}>
            <Link className="text-primary-dark" to="/search">
              <img
                className="text-primary-dark h-14"
                src="https://ominous.nz/storage/Cropped-Seafarer-Logo.svg"
                alt="Law Cyborg Search Logo"
              />
            </Link>
          </div>
          <div className={`w-[395px] h-[100vh] ${isLogoVisible ? 'hidden' : ''}`}>
            <MobileViewportWrapper />
          </div>
        </div>
      )}
      {isDesktopWide && (
        <Tippy touch={false} content={`Show/hide AI`} arrow={roundArrow} animation="shift-away" inertia={true}>
          <div className="-ml-1 mt-7 h-8">
            <Button
              icon="cpu"
              color="dark"
              size={16}
              action={handleToggle}
              className="bg-white rounded-full inline !h-8 !w-8 [&>*]:w-fit [&>*]:m-auto [&>*]:left-1/2 [&>*]:-translate-x-1/2 [&>*]:-translate-y-1/2 [&>*]:rotate-180 border hover:border-sky-500/20 hover:ring-2 hover:ring-sky-500/20"
            />
          </div>
        </Tippy>
      )}
      <div className="left flex-1">
        {isDebugMode && (
          <DebugTools debugState={debugState} updateDebugState={updateDebugState} elapsedTime={elapsedTime} />
        )}
        <div className="results mx-2 lg:mx-4">
          <div className="w-full">
            <SearchForm handleSubmit={handleSubmit} searchParams={searchParams} className="bg-white" />
          </div>
          <div className="results px-0 lg:px-2 my-4 max-w-[800px] mx-auto">
            {loading ? (
              <p className="ml-4">Loading...</p>
            ) : error ? (
              <p className="text-red-500">An error occurred while fetching results.</p>
            ) : (
              searchResults.length > 0 && (
                <>
                  <span className="resultSummary text-xs md:text-sm text-[#475569] ml-4">
                    {`Showing ${(currentPage - 1) * rowsPerPage + 1} to ${Math.min(
                      currentPage * rowsPerPage,
                      filteredResults.length
                    )} of ${filteredResults.length} results for "${searchInputValue}"`}
                  </span>

                  {paginatedResults.map((result, index) => (
                    <div key={index} className="border-b py-2">
                      <ResultItem
                        id={index + (currentPage - 1) * rowsPerPage}
                        {...result}
                        highlight={searchInputValue}
                      />
                    </div>
                  ))}

                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    visiblePages={isDesktop ? 5 : 3}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    currentRowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    className="custom-pagination"
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
      <FilterSection
        availablePracticeAreas={availablePracticeAreas}
        hasAccess={hasAccess}
        setRelevancyVsRecency={setRelevancyVsRecency}
        relevancyVsRecency={relevancyVsRecency}
        selectedPracticeAreaId={selectedPracticeAreaId}
      />
    </div>
  )
}

export default SearchResults
