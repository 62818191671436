import { Fragment, useEffect, useState, useContext } from 'react'
import React from 'react'
import { CopyIcon } from 'components/icon/copyIcon.js'
import Tippy from '@tippyjs/react'
import { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'
import 'tippy.js/animations/shift-away.css'
import { SettingsContext } from 'app/settings'
import { MessageFeedbackButton } from 'components/feedback/messageFeedbackButton'
import ResponseBody from './ResponseBody'
import { useAudio } from '../useAudio.js'
import { Icon } from 'components/lib'
import Style from './conversationMessage.tailwind.js'
import { TTSLoadingIcon } from 'icons'
import { usePlayingContext } from '../usePlayingContext'
import { ACT, ACTDEF, getSourceWith } from '@lawcyborg/packages'
import { CitationProvider } from './CitationContext'
import { toSnakeCase } from 'utils'
import ReferenceList from './references/ReferenceList'

const legislationNames = [ACT.name, ACTDEF.name]

const generateChunkLink = (chunk) => {
  const source = getSourceWith('name', chunk.source)
  const isLegislation = legislationNames.includes(source.superType)

  return isLegislation
    ? chunk.link
    : `${process.env.REACT_APP_S3_BUCKET_URL}/${source.documentTypes.find((doc) => doc.name === chunk.documentType).s3Path}/${toSnakeCase(chunk.title)}/Document.pdf#page=${chunk.page}`
}

export const ConversationResponse = React.memo(
  ({
    response,
    sources,
    accuracy,
    exchanges,
    streaming,
    audioKey,
    responseIndex,
    citations: references, // LV We should refer to citations as references from now on. TODO update this in other places
    seeAlsos,
  }) => {
    const settingsContext = useContext(SettingsContext)
    const hideAnswerFeedback = settingsContext?.settings?.answer_feedback ? true : false || false
    const [docLinks, setDocLinks] = useState([])
    const [copyText, setCopyText] = useState('')
    const { isAudioLoading, audioBlob, audioIndex, togglePlay, handleSynthesizeAndPlayAudio } = useAudio()
    const { playingIndex, setPlayingIndex, isGlobalLoading } = usePlayingContext()
    const isThisPlaying = playingIndex === responseIndex
    const [isExpanded, setIsExpanded] = useState(false)
    const toggleExpand = () => setIsExpanded(!isExpanded)

    useEffect(() => {
      if (sources) {
        const titles = sources.map((source) => source.title)
        const assistantLinks = sources.map((source) => source.link)
        const links = titles.map((title, index) => {
          // Get the page number and the title separately
          const match = title.includes(' at ') ? title.match(/(.+) at (\d+)$/) : [title, title, '']

          // Create a default URL for all titles
          const urlTitle = title.replace(/ at (\d+)$/g, '').replace(/\//g, '-')
          const formattedUrlTitle = encodeURIComponent(urlTitle) + '.pdf'
          let url = `https://docs.lawcyborg.com/source/${formattedUrlTitle}`

          // If the title includes a page number, include the page number in the URL
          if (match) {
            const [, , pageNumber] = match
            url += `#page=${pageNumber}`
          }

          if (assistantLinks[index]) {
            url = assistantLinks[index]
          }

          return url
        })

        setDocLinks(links)
      }
    }, [sources])

    const renderIcon = (image, size = 24, color = 'grey', disableHover = false) => {
      const iconClass = disableHover ? Style.noHoverIcon : Style.icon
      return <Icon className={iconClass} image={image} color={color} size={size} />
    }

    const handlePlayPause = async () => {
      if (isGlobalLoading) {
        console.log('Cannot play text while audio is loading')
        return
      }
      if (streaming) {
        console.log('Cannot play text while response is loading')
        return
      }
      if (isThisPlaying) {
        togglePlay(audioKey)
        setPlayingIndex(null)
      } else {
        setPlayingIndex(responseIndex)
        if (audioBlob && audioIndex === audioKey) {
          togglePlay(audioKey)
        } else {
          handleSynthesizeAndPlayAudio(response, audioKey)
        }
      }
    }

    useEffect(() => {
      if (isThisPlaying) {
        togglePlay(audioKey, true)
      }
    }, [isThisPlaying])

    return !response ? null : (
      <CitationProvider>
        <Fragment>
          <div
            className={`message overflow-visible whitespace-normal mx-3 px-4 text-left text-black/90 drop-shadow-lg border-x border-t border-black/5 animate-fadeIn ${`bg-white self-start border-x border-b text-black/80 py-2 ${
              sources?.length > 0 ? '' : 'rounded-b-md mb-8'
            }`}`}
            // LV Tailwind does not support overflow-wrap: anywhere, so specifying it here
            style={{ 'overflow-wrap': 'anywhere' }}
          >
            <div className="message__text relative">
              <>
                <div>
                  {isAudioLoading ? (
                    <div className={Style.iconWrapper}>
                      <TTSLoadingIcon />
                    </div>
                  ) : streaming ? (
                    <Tippy
                      touch={false}
                      content="Streaming..."
                      animation="shift-away"
                      arrow={roundArrow}
                      inertia={true}
                    >
                      <div className="inline top-0 float-right">{renderIcon('volume-2', 24, 'grey', true)}</div>
                    </Tippy>
                  ) : (
                    <>
                      {isThisPlaying ? (
                        <Tippy
                          touch={false}
                          content="Pause audio"
                          animation="shift-away"
                          arrow={roundArrow}
                          inertia={true}
                        >
                          <div className={Style.iconWrapper} onClick={() => handlePlayPause()}>
                            {renderIcon('pause-circle', 24)}
                          </div>
                        </Tippy>
                      ) : isGlobalLoading ? (
                        <Tippy
                          touch={false}
                          content="Audio is loading..."
                          animation="shift-away"
                          arrow={roundArrow}
                          inertia={true}
                        >
                          <div className="inline top-0 float-right">{renderIcon('volume-2', 24, 'grey', true)}</div>
                        </Tippy>
                      ) : (
                        <Tippy
                          touch={false}
                          content="Play audio"
                          animation="shift-away"
                          arrow={roundArrow}
                          inertia={true}
                        >
                          <div className={Style.iconWrapper} onClick={() => handlePlayPause()}>
                            {renderIcon('volume-2', 24)}
                          </div>
                        </Tippy>
                      )}
                    </>
                  )}
                </div>

                <CopyIcon size={24} color="grey" content={copyText} />
                {!hideAnswerFeedback && <MessageFeedbackButton chatHistory={exchanges} />}
                {accuracy || accuracy === 0 ? (
                  <Tippy
                    touch={false}
                    content="Estimated certainty"
                    animation="shift-away"
                    arrow={roundArrow}
                    inertia={true}
                  >
                    <div
                      className="inline top-0 float-right ml-2 hover:text-black/80 cursor-pointer select-none"
                      style={{ color: '#ccc', cursor: 'text' }}
                    >
                      {`${accuracy}%`}
                    </div>
                  </Tippy>
                ) : (
                  // Placeholder with the same dimensions and styling as the message.accuracy element
                  <div
                    className="inline top-0 float-right ml-2"
                    style={{
                      color: 'transparent',
                      cursor: 'text',
                      width: '40px',
                    }} // Adjust width as needed
                  >
                    100%
                  </div>
                )}
              </>

              <ResponseBody
                message={response}
                setCopyText={setCopyText}
                className="response-body"
                citations={references}
              />
            </div>
          </div>

          {references && references.length > 0 && (
            <pre className="bg-white mx-3 py-2 px-4 text-left text-black/80 drop-shadow-lg border-x border-b border-black/5 animate-fadeIn text-sm">
              <div className="whitespace-pre-wrap">
                <span className="font-semibold">References: </span>
                <span className="message__citations">
                  <ReferenceList references={references} />
                </span>
              </div>
            </pre>
          )}

          {seeAlsos && seeAlsos.length > 0 && (
            <pre className="bg-white mx-3 py-2 px-4 text-left text-black/80 drop-shadow-lg border-x border-b border-black/5 animate-fadeIn text-sm">
              <div className="whitespace-pre-wrap">
                <span className="font-semibold">See Also: </span>
                <span className="message__citations">
                  <ol className="ml-6 list-disc">
                    {seeAlsos.map((seeAlso, index) => (
                      <li key={index}>
                        <a
                          href={generateChunkLink(seeAlso)}
                          target="_blank"
                          rel="noreferrer"
                          className="transition-colors duration-300 ease-in-out hover:text-blue-500 block"
                        >{`${legislationNames.includes(seeAlso.source) ? seeAlso.documentType + ', s ' : ''}${seeAlso.title}${seeAlso.page ? ' at ' + seeAlso.page : ''}`}</a>
                      </li>
                    ))}
                  </ol>
                </span>
              </div>
            </pre>
          )}

          {sources && sources.length > 0 && (
            <div className="relative mb-8 mx-3 bg-white rounded-b-md drop-shadow-lg">
              {isExpanded && (
                <pre
                  className={`pt-2 px-4 text-left text-black/80  border-x  border-black/5 animate-expand text-sm overflow-hidden relative ${!isExpanded ? 'border-b' : ''} `}
                >
                  <div className="overflow-auto whitespace-pre-wrap">
                    <span className="font-semibold">Additional Resources: </span>
                    <span className="message__sources">
                      {sources.map((source, index) => (
                        <a
                          href={generateChunkLink(source)}
                          target="_blank"
                          rel="noreferrer"
                          className="transition-colors duration-300 ease-in-out hover:text-blue-500"
                          key={index}
                        >{`${legislationNames.includes(source.source) ? source.documentType + ', s ' : ''}${source.title}${source.page ? ' at ' + source.page : ''}${
                          index === sources.length - 1 ? '' : ', '
                        }`}</a>
                      ))}
                    </span>
                  </div>
                </pre>
              )}

              <div className={`text-center bg-gradient-to-t from-white to-transparent py-2 rounded-b-md`}>
                <button
                  onClick={toggleExpand}
                  className="hover:text-blue-700 text-sm transition-colors duration-300 ease-in-out"
                >
                  {isExpanded ? 'View Less' : 'View More'}
                </button>
              </div>
            </div>
          )}
        </Fragment>
      </CitationProvider>
    )
  }
)
