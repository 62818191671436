import { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const SearchContext = createContext()

const parseParam = (param) => {
  try {
    return param ? JSON.parse(param) : null
  } catch (error) {
    console.error('Error parsing URL param:', error)
    return null
  }
}

export const SearchProvider = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [searchResults, setSearchResults] = useState([])

  const [submittedSearchValue, setSubmittedSearchValue] = useState(searchParams.get('searchKey'))
  const [searchInputValue, setSearchInputValue] = useState(searchParams.get('searchKey'))
  const [strictSearch, setStrictSearch] = useState(parseParam(searchParams.get('strictSearch')) || false)
  const [selectedNamespaces, setSelectedNamespaces] = useState(parseParam(searchParams.get('nSpaces')))
  const [selectedCategories, setSelectedCategories] = useState(parseParam(searchParams.get('categories')))
  const [selectedPracticeAreaId, setSelectedPracticeAreaId] = useState(parseParam(searchParams.get('pArea')))
  const [relevancyVsRecency, setRelevancyVsRecency] = useState(parseParam(searchParams.get('relevancyVsRecency')) || 0)
  const [currentPage, setCurrentPage] = useState(parseParam(searchParams.get('page')) || 1)
  const [rowsPerPage, setRowsPerPage] = useState(parseParam(searchParams.get('rows')) || 10)
  const [documentTypeFilterCollapsed, setDocumentTypeFilterCollapsed] = useState(true)
  const [categoryFilterCollapsed, setCategoryFilterCollapsed] = useState(true)
  const [searchQueued, setSearchQueued] = useState(false)

  const urlParamToValue = useMemo(() => {
    return {
      searchKey: searchInputValue,
      pArea: selectedPracticeAreaId,
      page: currentPage,
      rows: rowsPerPage,
      strictSearch,
      relevancyVsRecency,
    }
  }, [searchInputValue, selectedPracticeAreaId, currentPage, rowsPerPage, strictSearch, relevancyVsRecency])

  useEffect(() => {
    const params = new URLSearchParams()
    Object.entries(urlParamToValue).forEach(([key, value]) => {
      if (value) params.set(key, value)
    })
    setSearchParams(params, { replace: true })
  }, [urlParamToValue, setSearchParams])

  const contextValue = useMemo(
    () => ({
      searchResults,
      setSearchResults,
      searchInputValue,
      setSearchInputValue,
      strictSearch,
      setStrictSearch,
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      submittedSearchValue,
      setSubmittedSearchValue,
      selectedNamespaces,
      setSelectedNamespaces,
      selectedCategories,
      setSelectedCategories,
      selectedPracticeAreaId,
      setSelectedPracticeAreaId,
      relevancyVsRecency,
      setRelevancyVsRecency,
      documentTypeFilterCollapsed,
      setDocumentTypeFilterCollapsed,
      categoryFilterCollapsed,
      setCategoryFilterCollapsed,
      searchQueued,
      setSearchQueued,
    }),
    [
      searchResults,
      searchInputValue,
      strictSearch,
      currentPage,
      rowsPerPage,
      submittedSearchValue,
      selectedNamespaces,
      selectedCategories,
      selectedPracticeAreaId,
      relevancyVsRecency,
      documentTypeFilterCollapsed,
      categoryFilterCollapsed,
      searchQueued,
    ]
  )

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>
}

export const useSearch = () => useContext(SearchContext)
