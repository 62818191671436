import React, { useEffect, useCallback } from 'react'
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import 'katex/dist/katex.min.css'
import JSONTablePart from './messageParts/JSONTablePart'
import ChartPart from './messageParts/ChartPart'
import MermaidPart from './messageParts/MermaidPart'
import ExcelFormulaPart from './messageParts/ExcelFormulaPart'
import Animatedloader from 'components/loader/animatedloader'
import CitationTooltip from './references/CitationTooltip'
import { CITATION_REGEX, convertSuperscriptToNumber, toSnakeCase } from 'utils'
import { getSourceWith } from '@lawcyborg/packages'
import { useCitation } from './CitationContext'

function cleanJsonString(jsonString) {
  // Remove trailing commas from objects and arrays
  jsonString = jsonString.replace(/,\s*([}\]])/g, '$1')

  // Replace single quotes around keys and values with double quotes
  jsonString = jsonString.replace(/'([^']*)'/g, '"$1"')

  return jsonString
}

// Generate link for citations
const generateChunkLink = (chunk) => {
  // We'll check if 'legislationNames' is needed or not during testing
  const source = getSourceWith('name', chunk.source)
  const isLegislation = source && source.superType === 'ACT'

  if (!source || !chunk) {
    return '#'
  }

  return isLegislation
    ? chunk.link
    : `${process.env.REACT_APP_S3_BUCKET_URL}/${source?.documentTypes?.find((doc) => doc.name === chunk.documentType)?.s3Path}/${toSnakeCase(chunk.title)}/Document.pdf#page=${chunk.page}`
}

const ResponseBody = React.memo(({ message, setCopyText, index, citations }) => {
  useEffect(() => {
    const cleanedCopyMessage = message
      .replace(/```\n*CHART_START.*CHART_END\n*```/gs, '[CHART HERE]')
      .replace(/```\n*TABLE_START.*TABLE_END\n*```/gs, '[TABLE HERE]')
      .replace(/```\n*MERMAID_START.*MERMAID_END\n*```/gs, '[DIAGRAM HERE]')
    setCopyText(cleanedCopyMessage, index)
  }, [message, index, setCopyText])

  const ProcessText = React.memo(({ children }) => {
    const { hoveredCitationIndex } = useCitation()

    if (typeof children !== 'string') return children

    const parts = []
    let lastIndex = 0
    let match

    while ((match = CITATION_REGEX.exec(children)) !== null) {
      // Add text before the citation
      if (match.index > lastIndex) {
        parts.push(children.substring(lastIndex, match.index))
      }

      const citationText = match[0]
      const citationIndex = convertSuperscriptToNumber(citationText) - 1

      // Check if citation index is valid before rendering
      if (!citations || citationIndex < 0 || citationIndex >= citations.length) {
        parts.push(<span key={`citation-${match.index}`}>{citationText}</span>)
      } else {
        // Check if this citation is currently hovered
        const isHovered = hoveredCitationIndex === citationIndex

        parts.push(
          <CitationTooltip key={`citation-${match.index}`} source={citations[citationIndex]}>
            <a
              href={generateChunkLink(citations[citationIndex])}
              target="_blank"
              rel="noreferrer"
              className={`text-black cursor-pointer transition-colors duration-200 ${isHovered ? 'citation-hovered' : ''}`}
              data-citation-index={citationIndex}
            >
              {citationText}
            </a>
          </CitationTooltip>
        )
      }
      lastIndex = match.index + match[0].length
    }

    // Add remaining text
    if (lastIndex < children.length) {
      parts.push(children.substring(lastIndex))
    }

    return parts.length > 0 ? <>{parts}</> : children
  })

  // Wrapper to process all children of an element
  const withProcessedText = (Component, props = {}) => {
    return ({ node, children, ...rest }) => {
      // Process each child separately
      const processedChildren = React.Children.map(children, (child) => {
        // If it's a text node, process it
        if (typeof child === 'string') {
          return <ProcessText>{child}</ProcessText>
        }
        return child
      })

      return (
        <Component {...props} {...rest}>
          {processedChildren}
        </Component>
      )
    }
  }

  // LV Required to get math working. Could not find way
  // of doing this mapping through the Markdown component
  // options prop'. Inline math should never be output by
  // ChatGPT due to our system prompt, but if it is just
  // treat it as block math
  message = message
    .replace(/\\\(\s?/g, '$$$$')
    .replace(/\s?\\\)/g, '$$$$')
    .replace(/\\\[\s*/g, '$$$$')
    .replace(/\s*\\\]/g, '$$$$')

  const code = useCallback(
    (props) => {
      const { children, className, node, ...rest } = props

      const is = (type) => {
        const trimmed = children.replace(/^\s+|\s+$/g, '')
        return (
          (trimmed.startsWith(`${type}_START`) && trimmed.endsWith(`${type}_END`)) ||
          // LV Sometimes chatgpt completely ignores the system
          // prompt and puts the type boundray on the same
          // line as the triple backticks. In these cases
          // the type will be interpreted as the code type
          // and passed as the className variable, therefore
          // do a check for this as well
          className?.endsWith(`${type}_START`)
        )
      }

      const isLoading = (type) => {
        const trimmed = children.replace(/^\s+|\s+$/g, '')
        return trimmed.startsWith(`${type}_START`) && !trimmed.endsWith(`${type}_END`)
      }

      const removeBoundary = (type) => {
        const unbounded = children.replace(`${type}_START`, '').replace(`${type}_END`, '')
        return unbounded.replace(/^\s+|\s+$/g, '')
      }

      if (!children) return null
      const supportedCodeLanguageMatch = /language-(\w+)/.exec(className || '')

      const customTypes = ['TABLE', 'CHART', 'MERMAID']
      const requiresJSONClean = ['TABLE', 'CHART']
      const loading = customTypes.some(isLoading)

      if (loading) {
        return <Animatedloader />
      }

      const customComponents = {
        TABLE: JSONTablePart,
        CHART: ChartPart,
        MERMAID: MermaidPart,
        EXCEL: ExcelFormulaPart,
      }

      const type = customTypes.find(is)
      if (type) {
        let inputMessage = removeBoundary(type)
        if (requiresJSONClean.includes(type)) {
          inputMessage = cleanJsonString(removeBoundary(type))
        }
        const Component = customComponents[type]
        return <Component message={inputMessage} setCopyText={setCopyText} index={index} />
      }

      return supportedCodeLanguageMatch ? (
        <SyntaxHighlighter
          {...rest}
          PreTag="div"
          children={String(children).replace(/\n$/, '')}
          language={supportedCodeLanguageMatch[1]}
        />
      ) : (
        <code {...rest} className={className}>
          {children}
        </code>
      )
    },
    [setCopyText, index]
  )

  // Apply text processing to all text-containing elements
  const p = withProcessedText('p', { className: 'mb-3' })
  const li = withProcessedText('li', { className: 'mb-3' })
  const ul = withProcessedText('ul', { className: 'list-disc list-outside pl-7 mb-3' })
  const ol = withProcessedText('ol', { className: 'list-decimal list-outside pl-7 mb-3' })

  // Handle headers
  const createHeader = (level) => {
    const HeaderTag = `h${level}`
    return withProcessedText(HeaderTag, { className: 'mb-3' })
  }

  const pre = useCallback(({ children, ...props }) => {
    return (
      <pre className="mb-6" {...props}>
        {children}
      </pre>
    )
  }, [])

  return (
    <>
      <Markdown
        remarkPlugins={[[remarkMath, { singleDollarTextMath: false }], remarkGfm]}
        rehypePlugins={[rehypeKatex]}
        children={message}
        components={{
          code,
          p,
          ul,
          ol,
          li,
          h1: createHeader(1),
          h2: createHeader(2),
          h3: createHeader(3),
          h4: createHeader(4),
          h5: createHeader(5),
          h6: createHeader(6),
          pre,
          // Also process other text-containing elements
          strong: withProcessedText('strong'),
          em: withProcessedText('em'),
          blockquote: withProcessedText('blockquote', { className: 'border-l-4 border-gray-300 pl-4 italic' }),
          a: withProcessedText('a'),
        }}
      />
    </>
  )
})

export default ResponseBody
