/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import { useState, useEffect, createContext, useCallback, useContext, useMemo, useRef } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { countries, moduleIds, practiceAreaIds } from '@lawcyborg/packages'
import { useAccess } from 'hooks'

// auth context
export const AuthContext = createContext()

const useAPI = require('components/lib').useAPI
const Event = require('components/lib').Event
const permissions = require('./permissions')

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState(cache)
  const initAuthData = useAPI(user ? '/api/auth' : null)
  const [authData, setAuthData] = useState()
  const processedAuthData = useRef(false)

  const availableLicenses = useMemo(() => {
    const availableLicensesSet = new Set(user?.userLicenses?.map((license) => license.module_id))
    return Array.from(availableLicensesSet)
  }, [user?.userLicenses])

  const userHasNzPracticeAreaBundle = useMemo(() => availableLicenses.includes(moduleIds.NZ_PRACTICE_AREA_BUNDLE), [availableLicenses])

  const availablePracticeAreas = useMemo(() => {
    if(userHasNzPracticeAreaBundle) return Object.values(practiceAreaIds);

    return availableLicenses.filter((licence) => Object.values(practiceAreaIds).includes(licence))
  }, [availableLicenses])

  useEffect(() => {
    // update the auth status
    if (!initAuthData.loading && initAuthData.data && !processedAuthData.current) {
      processedAuthData.current = true;
      if (initAuthData.data.authenticated) {
        updateUserData(initAuthData.data)
      } else {
        signout()
      }
    }
    if (initAuthData.error?.response?.status === 401) {
      signout()
    }
  }, [initAuthData])

  useEffect(() => {
    // update the auth status
    if (!authData) return
    if (!authData.loading && authData.data) {
      if (authData.data.authenticated) {
        updateUserData(authData.data)
      } else {
        signout()
      }
    }
    if (authData.error?.response?.status === 401) {
      signout()
    }
  }, [authData])

  function signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data))
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
      Event('signin')

      if (!res.data.verified) return (window.location = '/signup/verify')

      if (!res.data.plan) return (window.location = '/signup/plan')

      // return window.location = '/';
      return (window.location = res.data.onboarded ? '/' : '/welcome')
    }
  }

  async function signout() {
    axios.delete('/api/auth')
    localStorage.clear()
    return (window.location = '/signin')
  }

  async function switchAccount(id) {
    const res = await axios({
      method: 'post',
      url: '/api/auth/switch',
      data: { account: id },
    })

    if (res.data) signin(res)
  }

  function updateUserData(data) {
    if (localStorage.getItem('user')) {
      let updatedUser = JSON.parse(localStorage.getItem('user'))
      let hasChanges = false;
      
      for (let key in data) {
        if (Array.isArray(data[key])) {
          updatedUser[key] = data[key]
          hasChanges = true;
        } else if (typeof data[key] === 'object') {
          if (!updatedUser[key] || typeof updatedUser[key] !== 'object') {
            updatedUser[key] = {}
            hasChanges = true;
          }

          for (let innerKey in data[key]) {
            if (JSON.stringify(updatedUser[key][innerKey]) !== JSON.stringify(data[key][innerKey])) {
              updatedUser[key][innerKey] = data[key][innerKey]
              hasChanges = true;
            }
          }
        } else if (updatedUser[key] !== data[key]) {
          updatedUser[key] = data[key]
          hasChanges = true;
        }
      }

      if (updatedUser.accounts?.length > 0) {
        if (!updatedUser.accounts[0].country) {
          updatedUser.accounts[0].country = countries.nz
          hasChanges = true;
        }
      }

      localStorage.setItem('user', JSON.stringify(updatedUser))
      
      if (hasChanges) {
        setUser({...updatedUser})
      }
    }
  }

  const refreshAuth = useCallback(() => {
    axios({
      url: '/api/auth',
      method: 'get',
      data: {},
    }).then((res) => {
      setAuthData(res.data)
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: updateUserData,
        switchAccount: switchAccount,
        refreshAuth,
        permission: permissions[user?.permission],
        availableLicenses,
        availablePracticeAreas,
      }}
      {...props}
    />
  )
}

/**
 * Get the Auth context
 * @returns {{
 *   token?: string,
 *   plan?: string,
 *   permission?: string,
 *   name?: string,
 *   accounts?: object[],
 *   user_id?: string,
 *   account_id?: string,
 *   has_password?: boolean,
 *   onboarded?: boolean,
 *   verified?: boolean,
 *   feature_flags?: object,
 *   userLicenses?: object[],
 *   jwt_token?: boolean,
 *   social_token?: boolean,
 *   authenticated?: boolean,
 *   subscription?: string,
 *   availableLicenses?: string[],
 *   availablePracticeAreas?: string[],
 *   authMethod?: string
 * }} Auth User object with all properties
 */
export function useAuth() {
  return useContext(AuthContext).user || {}
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  // check user exists
  const user = JSON.parse(localStorage.getItem('user'))
  const hasAccess = useAccess()

  const path = window.location.pathname
  const permittedRoutes = ['/account/billing', '/account/products', '/account/2fa', '/account/users', '/signup/plan', '/account/upgrade', '/account', '/account/profile', '/account/secure']

  if (user?.token) {
    if (permissions[user.permission][props.permission]) {
      if (user.verified) {
        // user has no plan
        if (!user.plan && path !== '/account/profile' && path !== '/signup/plan') return <Navigate to="/signup/plan" />

        // user has no subscription
        if (
          user.subscription !== 'active' &&
          user.subscription !== 'trialing' &&
          user.permission !== 'master' &&
          !permittedRoutes.includes(path)
        )
          return <Navigate to="/account/billing" />

        if(
          user.magic_link_required && 
          user.authMethod !== 'magic_link' &&
          !permittedRoutes.includes(path)
        ) {
          return <Navigate to="/account/secure" />
        }

        // User doesn't have feature flag for page
        if (props.featureFlag && !user.feature_flags[props.featureFlag]) return <Navigate to="/" />
        // If user doesn't have License
        // FIXME: CREATE A PAGE THAT SAY'S: YOU DON'T HAVE ENOUGH LICENSE TO GET THE PAGE
        if (props.license && !hasAccess(props.license)) {
          if (!hasAccess(moduleIds.ESSENTIAL_PLAN)) return <Navigate to="/account/profile" />
          else return <Navigate to="/" />
        }
        // hide plan view if user is on plan
        if (user.plan && path === '/signup/plan') return <Navigate to="/" />
        // hide verified view if user is verified
        if (path === '/signup/verify') return <Navigate to="/" />
      } else {
        // user is not verified
        if (path !== '/account/profile' && path !== '/signup/verify') return <Navigate to="/signup/verify" />
      }

      // user is good
      return props.children
    }
  }

  // user is not authenticated
  return <Navigate to="/signin" />
}
