import React, { useState, Fragment, useContext } from 'react'
import { AccountNav, Animate, Message, Button, AuthContext } from 'components/lib'

export function SecureAccount() {
  const context = useContext(AuthContext)
  const [showInfo, setShowInfo] = useState(false)

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Message
          type="warning"
          title="Account Locked for Security"
          closable={false}
          text={
            <p className="pt-2 text-sm">
              We have detected multiple devices logging into your account frequently. For security, we have logged out
              all devices and turned your account to magic-link login only. This means that you cannot login via your
              password and will need to click a link that you are emailed to login. This system will deactivate when
              your password is changed, or the number of frequent devices decreases.
            </p>
          }
        >
          {!showInfo ? (
            <div className="mt-6 flex flex-col sm:flex-row gap-4 sm:justify-start">
              <Button
                text="My Account is Already Secure"
                textOnly
                className="!text-orange-500 border-2 border-orange-500 hover:bg-orange-50 hover:border-orange-600 font-semibold rounded-xl px-12 py-3 ease-in-out duration-500"
                action={() => setShowInfo(true)}
              />
              <Button text="Close" action={context.signout} color="orange" />
            </div>
          ) : (
            <div className="mt-4 text-sm leading-relaxed  space-y-3">
              <h2 className="text-xl font-semibold text-orange-400">Why We Charge Per User</h2>
              <p>
                Law Cyborg is a small, Auckland-based startup on a mission to make the world’s most advanced
                technologies accessible to everyday accountants and lawyers.
              </p>
              <p>
                Each question that goes through our system takes substantial compute to answer, causing us to incur real
                costs. Due to this, we must charge per user on the platform, otherwise our costs to deliver the product
                can supersede our subscription fee.
              </p>
              <p>
                With additional users, you receive your own private chat history, and the ability to email the AI at{' '}
                <a href="mailto:ai@query.lawcyborg.com" className="text-blue-700 underline hover:text-blue-900">
                  ai@query.lawcyborg.com
                </a>{' '}
                for answers. More collaborative features are coming too. Firms that roll Law Cyborg out across their
                whole team see large productivity increases and decreased hesitancy to ask questions. You can also book
                a free masterclass for your whole firm with our team here.
              </p>
              <div className="pt-4 flex flex-col sm:flex-row gap-4">
                <Button
                  text="How to invite more users"
                  textOnly
                  className="!text-orange-500 border-2 border-orange-500 hover:bg-orange-50 hover:border-orange-600 font-semibold rounded-xl px-12 py-3 ease-in-out duration-500"
                  action={() => window.open('https://lawcyborg.com/FAQ', '_blank')}
                />
                <Button text="Close" action={context.signout} color="orange" />
              </div>
            </div>
          )}
        </Message>
      </Animate>
    </Fragment>
  )
}
